<template>
  <v-dialog :value="show && !cancel" fullscreen persistant class="noscroll">
    <v-card d-flex flex-column dark class="pt-10 mt-10" color="charcoal">
      <v-sheet rounded class="pa-2 mx-2 charcoal ">
        <v-row justify="center" class="my-1">
          <v-img :src="logo" contain title="Logo" :height="isPhone ? 50 : 80" />
        </v-row>
        <video-background
          :src="videoLoop"
          style="max-height: 200px; height: 50vh;opacity:0.40"
          overlay="linear-gradient(45deg,#2a4ae430,#fb949e6b)"
        >
        </video-background>
      </v-sheet>
      <v-spacer />
      <v-card-actions
        :class="textSize + ' paper--text text--darken-2 px-0 pt-10 mt-5'"
      >
        <v-spacer />
        {{ title }}
        <v-spacer />
      </v-card-actions>
      <v-card-actions class="mb-5">
        <v-spacer />
        <v-btn color="silver" plain @click.self="cancelDialog" x-large>
          Cancel
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import util from '@/mixins/util.js'
export default {
  mixins: [util],
  data: () => ({
    cancel: false,
    videoLoop: null,
    logo: null
  }),
  props: {
    title: {
      type: String,
      default: 'Loading'
    },
    color: {
      type: String,
      default: 'accent'
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  beforeMount() {
    this.cancel = false

    this.videoLoop = this.isPhone
      ? require(`@/assets/LoopSmall.mp4`)
      : require(`@/assets/Loop.mp4`)
    this.logo = require('@/assets/Logo100.png')
  },
  beforeDestroy() {
    this.cancel = false
    this.videoLoop = null
    this.logo = null
  },
  mounted() {},
  methods: {
    cancelDialog(e) {
      e.preventDefault()
      this.cancel = true
    }
  }
}
</script>
